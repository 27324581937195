import React, { useMemo } from "react";
import Avatar from "@material-ui/core/Avatar";
import makeStyles from "@material-ui/styles/makeStyles";
import Person from "@material-ui/icons/Person";
import { StateContextType } from "../../../../state";
import { getUniqDarkColour } from "../../../../utils";

const useStyles = makeStyles({
  avatar: {
    color: "white",
    fontSize: "1rem"
  }
});

export function getInitials(name: string) {
  return name
    .split(" ")
    .map(text => text[0])
    .join("")
    .substr(0, 3)
    .toUpperCase();
}

export default function UserAvatar({
  user
}: {
  user: StateContextType["user"];
}) {
  const classes = useStyles();
  const { displayName, photoURL, email } = user!;

  const style = useMemo(() => {
    return { backgroundColor: getUniqDarkColour(displayName) };
  }, [displayName]);

  return photoURL ? (
    <Avatar src={photoURL} className={classes.avatar} style={style} />
  ) : (
    <Avatar className={classes.avatar} style={style}>
      {displayName || email ? (
        getInitials(displayName || email || "")
      ) : (
        <Person />
      )}
    </Avatar>
  );
}
