import React, { ChangeEvent, FormEvent, useState } from "react";
import {
  Typography,
  makeStyles,
  TextField,
  Grid,
  Button,
  InputLabel,
  Theme
} from "@material-ui/core";
import { useAppState } from "../../../state";

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: "1em"
  },
  hideBlockSm: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  inputContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: "1.5em 0 3.5em",
    "& div:not(:last-child)": {
      marginRight: "1em"
    },
    [theme.breakpoints.down("sm")]: {
      margin: "1.5em 0 2em"
    }
  },
  textFieldContainer: {
    width: "100%"
  },
  continueButton: {
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  }
}));

interface RoomNameScreenProps {
  roomName: string;
  setRoomName: (roomName: string) => void;
  onJoinExistingRoom: (event: FormEvent<HTMLFormElement>) => void;
  onCreateNewRoom: () => Promise<void>;
}

export default function RoomNameScreen({
  roomName,
  setRoomName,
  onJoinExistingRoom,
  onCreateNewRoom
}: RoomNameScreenProps) {
  const classes = useStyles();
  const { user } = useAppState();
  const [isCreateNewDisabled, setIsCreateNewDisabled] = useState(false);

  const handleRoomNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const rawValue = event?.target?.value;

    const matchParts = rawValue.match(
      /^.+room\/([a-z0-9]+-[a-z0-9]+-[a-z0-9]+)$/i
    );

    if (matchParts && matchParts[1]) {
      setRoomName(matchParts[1]);
      return;
    }

    setRoomName(event.target.value);
  };

  return (
    <div className="RoomNameScreen">
      <Typography
        variant="h5"
        className={`${classes.gutterBottom} ${classes.hideBlockSm}`}
      >
        Enter Party Room
      </Typography>

      {user?.uid && (
        <Grid container justifyContent="center" direction="column">
          <Button
            variant="contained"
            color="primary"
            className={classes.continueButton}
            onClick={() => {
              setIsCreateNewDisabled(true);
              onCreateNewRoom().then(() => setIsCreateNewDisabled(false));
            }}
            disabled={isCreateNewDisabled}
          >
            {isCreateNewDisabled ? "Loading new room..." : "Create new room"}
          </Button>

          <Typography variant="h6" align="center" style={{ margin: "1rem 0" }}>
            OR
          </Typography>
        </Grid>
      )}

      <Typography variant="body1">
        Please enter the name of the Party Room you would like to enter.
      </Typography>
      <form onSubmit={onJoinExistingRoom}>
        <div className={classes.inputContainer}>
          <div className={classes.textFieldContainer}>
            <InputLabel shrink htmlFor="input-room-name">
              Party Room
            </InputLabel>
            <TextField
              autoCapitalize="false"
              id="input-room-name"
              variant="outlined"
              fullWidth
              size="small"
              value={roomName}
              onChange={handleRoomNameChange}
            />
          </div>
        </div>
        <Grid container justifyContent="flex-end">
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={!roomName}
            className={classes.continueButton}
          >
            Next
          </Button>
        </Grid>
      </form>
    </div>
  );
}
