import React, { useEffect, useMemo, useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import { makeStyles, Theme } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import DrawerDialogHeader from "../DrawerDialogHeader/DrawerDialogHeader";
import clsx from "clsx";
import { fetchMedia } from "../../state/APIs";
import { useAppState } from "../../state";
import { WpMedia } from "../../../commonTypes";
import { getMediaThumb } from "../../utils/mediaUtils";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import { TextField } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Fuse from "fuse.js";

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    display: "flex",
    width: theme.rightDrawerWidth,
    height: `calc(100% - ${theme.footerHeight}px)`
  },
  thumbnailContainer: {
    display: "flex",
    flexWrap: "wrap",
    padding: "5px",
    overflowY: "auto"
  },
  thumbRoot: {
    margin: "5px",
    width: "calc(50% - 10px)",
    display: "flex",
    position: "relative",
    flexDirection: "column",
    cursor: "pointer"
  },
  thumbImage: {
    width: "100%",
    height: 90,
    objectFit: "cover",
    borderRadius: "10px",
    border: `solid ${theme.palette.grey[400]}`,
    "&:hover": {
      "& svg": {
        color: `${theme.palette.primary.main}`
      }
    },
    "&.selected": {
      border: `solid ${theme.palette.primary.main}`,
      "& svg": {
        color: `${theme.palette.primary.main}`
      }
    }
  },
  thumbTitle: {
    padding: "5px 10px",
    fontSize: "14px",
    fontWeight: "bold",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  videoPlayIcon: {
    zIndex: 1,
    color: "white",
    width: "50%",
    height: 50,
    top: 20,
    margin: "auto 25%",
    pointerEvents: "none",
    position: "absolute"
  },
  searchInput: {
    width: "100%",
    marginBottom: 12
  },
  typeFilterBtnGroup: {
    display: "flex",
    width: "100%",
    marginBottom: 12
  },
  typeFilterBtn: {
    width: "33.33%"
  }
}));

type TypeFilterKey = "all" | "videos" | "images";

interface TypeFilterOption {
  key: TypeFilterKey;
  label: string;
}

const typeFilterOptions: TypeFilterOption[] = [
  { key: "all", label: "All" },
  { key: "images", label: "Images" },
  { key: "videos", label: "Videos" }
];

function MediaSelectionDialog() {
  const classes = useStyles();
  const { showMediaDialog, setShowMediaDialog } = useAppState();
  const { sendShareMsg, setSharedMediaItem } = useVideoContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [typeFilter, setTypeFilter] = useState<TypeFilterKey>("all");
  const [media, setMedia] = useState<WpMedia[]>([]);

  const filteredMedia = useMemo(() => {
    const searchedMedia: WpMedia[] = search
      ? (new Fuse(media, { keys: ["title"] })
          .search(search)
          .map(({ item }) => item) as any)
      : media;

    return searchedMedia.filter(
      (mediaItem: WpMedia) =>
        mediaItem.type === typeFilter || typeFilter === "all"
    );
  }, [media, typeFilter, search]);

  useEffect(() => {
    setSearch("");
    setTypeFilter("all");
  }, [showMediaDialog]);

  useEffect(() => {
    setIsLoading(true);
    fetchMedia()
      .then(setMedia)
      .catch()
      .then(() => setIsLoading(false));
  }, []);

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={showMediaDialog}
      transitionDuration={0}
      classes={{
        paper: classes.drawer
      }}
    >
      <DrawerDialogHeader onClose={() => setShowMediaDialog(false)}>
        Media
      </DrawerDialogHeader>
      <div className={classes.thumbnailContainer}>
        <TextField
          autoCapitalize="false"
          variant="outlined"
          fullWidth
          size="small"
          className={classes.searchInput}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
          value={search}
          onChange={event => setSearch(event?.target?.value || "")}
        />

        <ButtonGroup
          disableElevation
          color="primary"
          className={classes.typeFilterBtnGroup}
        >
          {typeFilterOptions.map(typeFilterOption => (
            <Button
              key={typeFilterOption.key}
              className={classes.typeFilterBtn}
              variant={
                typeFilter === typeFilterOption.key ? "contained" : undefined
              }
              onClick={() => setTypeFilter(typeFilterOption.key)}
            >
              {typeFilterOption.label}
            </Button>
          ))}
        </ButtonGroup>

        {isLoading || !showMediaDialog ? (
          <LinearProgress />
        ) : (
          filteredMedia.map((mediaItem: WpMedia) => (
            <div
              key={mediaItem.id}
              className={classes.thumbRoot}
              onClick={() => {
                sendShareMsg(mediaItem);
                setSharedMediaItem(mediaItem);
              }}
            >
              <img
                className={clsx(classes.thumbImage, {
                  selected: false
                })}
                src={getMediaThumb(mediaItem)}
                alt={mediaItem.title}
              />
              <div className={classes.thumbTitle}>
                {mediaItem?.title?.length > 60
                  ? `${mediaItem.title.substr(0, 55)}...`
                  : mediaItem.title}
              </div>
              {mediaItem.type === "videos" ? (
                <PlayCircleOutlineIcon className={classes.videoPlayIcon} />
              ) : null}
            </div>
          ))
        )}
      </div>
    </Drawer>
  );
}

export default MediaSelectionDialog;
