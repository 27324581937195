import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import EndCallButton from "../Buttons/EndCallButton/EndCallButton";
import Menu from "../MenuBar/Menu/Menu";
import CopyLocationHref from "../CopyLocationHref/CopyLocationHref";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: "white",
    paddingLeft: "1em",
    display: "none",
    height: `${theme.mobileTopBarHeight}px`,
    [theme.breakpoints.down("sm")]: {
      display: "flex"
    }
  },
  endCallButton: {
    height: "28px",
    fontSize: "0.85rem",
    padding: "0 0.6em"
  },
  settingsButton: {
    [theme.breakpoints.down("sm")]: {
      height: "28px",
      minWidth: "28px",
      border: "1px solid rgb(136, 140, 142)",
      padding: 0,
      margin: "0 1em"
    }
  },
  leaveRoomContainer: {
    flex: 1,
    textAlign: "right"
  },
  narrowTextForSm: {
    [theme.breakpoints.down("sm")]: {
      letterSpacing: -0.5
    }
  }
}));

export default function MobileTopMenuBar() {
  const classes = useStyles();
  const { room } = useVideoContext();

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      className={`MobileTopMenuBar ${classes.container}`}
    >
      <Typography variant="subtitle1" className={classes.narrowTextForSm}>
        {room!.name}
      </Typography>
      <CopyLocationHref />

      <div className={classes.leaveRoomContainer}>
        <EndCallButton
          className={`${classes.endCallButton} ${classes.narrowTextForSm}`}
        />
        <Menu buttonClassName={classes.settingsButton} />
      </div>
    </Grid>
  );
}
