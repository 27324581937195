import React from "react";
import { copyTextToClipboard } from "../../utils/clipboardUtils";
import { Button, makeStyles, Theme } from "@material-ui/core";
import { ReactComponent as CopyIcon } from "../../icons/copy.svg";
import SvgIcon from "@material-ui/core/SvgIcon";

const useStyles = makeStyles((theme: Theme) => ({
  narrowTextForSm: {
    [theme.breakpoints.down("sm")]: {
      letterSpacing: -0.5
    }
  }
}));

export default function CopyLocationHref() {
  const classes = useStyles();

  return (
    <Button
      startIcon={<SvgIcon component={CopyIcon} viewBox="0 0 512 512" />}
      onClick={() => copyTextToClipboard(window.location.href)}
      className={classes.narrowTextForSm}
    >
      Copy Link
    </Button>
  );
}
