import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useAppState } from "../../state";

export default function PrivateRoute({ children, ...rest }: RouteProps) {
  const { isAuthReady, user } = useAppState();

  if (!user && !isAuthReady) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={({ location }) => {
        const pathname = /\/room\/.+/.test(location?.pathname || "")
          ? `/login${location?.pathname}`
          : "/login";

        return user ? children : <Redirect to={{ pathname }} />;
      }}
    />
  );
}
