import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import EndCallButton from "../Buttons/EndCallButton/EndCallButton";
import { isMobile } from "../../utils";
import Menu from "./Menu/Menu";
import useRoomState from "../../hooks/useRoomState/useRoomState";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import { Typography, Grid, Hidden } from "@material-ui/core";
import ToggleAudioButton from "../Buttons/ToggleAudioButton/ToggleAudioButton";
import ToggleVideoButton from "../Buttons/ToggleVideoButton/ToggleVideoButton";
import ToggleScreenShareButton from "../Buttons/ToogleScreenShareButton/ToggleScreenShareButton";
import CopyLocationHref from "../CopyLocationHref/CopyLocationHref";
import IconButton from "@material-ui/core/IconButton";
import { useAppState } from "../../state";
import { ReactComponent as PhotoFilmIcon } from "../../icons/photo-film.svg";
import SvgIcon from "@material-ui/core/SvgIcon";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.default,
      bottom: 0,
      left: 0,
      right: 0,
      height: `${theme.footerHeight}px`,
      position: "fixed",
      display: "flex",
      padding: "0 1.43em",
      zIndex: 10,
      [theme.breakpoints.down("sm")]: {
        height: `${theme.mobileFooterHeight}px`,
        padding: 0
      }
    },
    screenShareBanner: {
      position: "fixed",
      zIndex: 8,
      bottom: `${theme.footerHeight}px`,
      left: 0,
      right: 0,
      height: "104px",
      background: "rgba(0, 0, 0, 0.5)",
      "& h6": {
        color: "white"
      },
      "& button": {
        background: "white",
        color: theme.brand,
        border: `2px solid ${theme.brand}`,
        margin: "0 2em",
        "&:hover": {
          color: "#600101",
          border: `2px solid #600101`,
          background: "#FFE9E7"
        }
      }
    },
    hideMobile: {
      display: "initial",
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    roomNameContainer: {
      flex: "1 1 1%",
      [theme.breakpoints.down("md")]: {
        flex: "1 1 7%"
      }
    },
    roomNameLabel: {
      display: "inline-block",
      marginRight: "1rem"
    },
    shareMediaBtn: {
      position: "absolute",
      background: "white",
      bottom: "112px",
      right: "40px",
      "&:hover": {
        background: "white"
      }
    }
  })
);

export default function MenuBar() {
  const classes = useStyles();
  const { room, isSharingScreen, toggleScreenShare } = useVideoContext();
  const roomState = useRoomState();
  const isReconnecting = roomState === "reconnecting";
  const { user, showMediaDialog, setShowMediaDialog } = useAppState();

  return (
    <>
      {isSharingScreen && (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.screenShareBanner}
        >
          <Typography variant="h6">You are sharing your screen</Typography>
          <Button onClick={() => toggleScreenShare()}>Stop sharing</Button>
        </Grid>
      )}
      <footer className={classes.container}>
        <Grid container justifyContent="space-around" alignItems="center">
          <Hidden smDown>
            <Grid className={classes.roomNameContainer}>
              <Typography variant="body1" className={classes.roomNameLabel}>
                {room!.name}
              </Typography>
              <CopyLocationHref />
            </Grid>
          </Hidden>
          <Grid item>
            <Grid container justifyContent="center">
              <ToggleAudioButton disabled={isReconnecting} />
              <ToggleVideoButton disabled={isReconnecting} />
              {!isSharingScreen && !isMobile && (
                <ToggleScreenShareButton disabled={isReconnecting} />
              )}
              <Hidden smDown>
                <Menu />
              </Hidden>
            </Grid>
          </Grid>
          <Hidden smDown>
            <Grid style={{ flex: 1 }}>
              <Grid container justifyContent="flex-end">
                <EndCallButton />
              </Grid>
            </Grid>
          </Hidden>

          {user?.uid && !showMediaDialog && (
            <IconButton
              color="primary"
              className={classes.shareMediaBtn}
              onClick={() => setShowMediaDialog(true)}
              title="Share media"
            >
              <SvgIcon
                fontSize="large"
                component={PhotoFilmIcon}
                viewBox="0 0 640 512"
                style={{ fill: "black" }}
              />
              {/*<ShareIcon fontSize="large" style={{ fill: "black" }} />*/}
            </IconButton>
          )}
        </Grid>
      </footer>
    </>
  );
}
