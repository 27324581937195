import React, { ChangeEvent, useMemo, useState } from "react";
import { useAppState } from "../../state";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IntroContainer from "../IntroContainer/IntroContainer";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useParams } from "react-router-dom";
import { isValidEmail } from "../../utils";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { useInputState } from "../../utils/useInputState";

const useStyles = makeStyles(() => ({
  gutterBottom: {
    marginBottom: "1em"
  },
  submitButton: {
    width: "100%"
  },
  flexFullWidth: {
    width: "100%",
    marginBottom: "1em"
  },
  errorMessage: {
    color: "red",
    display: "flex",
    alignItems: "center",
    margin: "1em 0 0.2em",
    "& svg": {
      marginRight: "0.4em"
    }
  }
}));

export default function LoginPage() {
  const classes = useStyles();
  const { signInAsGuest, user, isAuthReady } = useAppState();
  const { urlRoomName } = useParams();
  const history = useHistory();
  const [guestUser, isGuestUserDirty, setGuestUser] = useInputState("");
  const [guestEmail, isGuestEmailDirty, setGuestEmail] = useInputState("");
  const [consent, setConsent] = useState(false);

  const errors = useMemo(() => {
    const errorsList = [];

    if (!guestUser) {
      errorsList.push({
        message: "Name cannot be empty",
        display: isGuestUserDirty
      });
    }

    if (!guestEmail) {
      errorsList.push({
        message: "Email cannot be empty",
        display: isGuestEmailDirty
      });
    } else if (!isValidEmail(guestEmail)) {
      errorsList.push({
        message: "Entered email is invalid",
        display: isGuestEmailDirty
      });
    }

    return errorsList;
  }, [guestUser, isGuestUserDirty, guestEmail, isGuestEmailDirty]);

  const displayError = useMemo(() => {
    if (!errors.length) {
      return "";
    }

    return errors
      .filter(error => error.display)
      .map(error => error.message)
      .join(". ");
  }, [errors]);

  const isAuthEnabled = Boolean(process.env.REACT_APP_SET_AUTH);

  if (user || !isAuthEnabled) {
    history.replace({ pathname: urlRoomName ? `/room/${urlRoomName}` : "/" });
  }

  if (!isAuthReady) {
    return null;
  }

  return (
    <IntroContainer>
      <Typography variant="h5" className={classes.gutterBottom}>
        {urlRoomName ? (
          <span>Sign in to join "{urlRoomName}"</span>
        ) : (
          "Sign in to join a room"
        )}
      </Typography>

      <Grid container justifyContent="space-between">
        <TextField
          id="guest-username"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setGuestUser((e?.target?.value || "").trim());
          }}
          variant="outlined"
          size="small"
          placeholder="Your name"
          className={classes.flexFullWidth}
        />
        <TextField
          id="guest-email"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setGuestEmail((e?.target?.value || "").trim());
          }}
          variant="outlined"
          size="small"
          placeholder="Your email"
          className={classes.flexFullWidth}
          type="email"
        />
        <FormControlLabel
          className={classes.gutterBottom}
          control={
            <Checkbox
              checked={consent}
              onChange={event => setConsent(event?.target?.checked)}
              name="consent"
              color="primary"
            />
          }
          label={
            <span>
              I accept the{" "}
              <a
                href="https://hausberlinger.com/datenschutz/"
                target="_blank"
                rel="noopener noreferrer"
              >
                privacy policy
              </a>
            </span>
          }
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={!!errors.length || !consent}
          className={classes.submitButton}
          onClick={() =>
            !errors.length &&
            consent &&
            signInAsGuest?.({ displayName: guestUser, email: guestEmail })
          }
        >
          Sign-In
        </Button>

        {displayError && (
          <Typography variant="caption" className={classes.errorMessage}>
            <ErrorOutlineIcon />
            {displayError}
          </Typography>
        )}
      </Grid>
    </IntroContainer>
  );
}
