import { WpMedia } from "../../commonTypes";

export function getYoutubeID(url: string) {
  const preId = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  return preId[2] !== undefined ? preId[2].split(/[^0-9a-z_-]/i)[0] : preId[0];
}

export function getYoutubeThumb(url: string) {
  return `https://img.youtube.com/vi/${getYoutubeID(url)}/default.jpg`;
}

export function getYoutubeIframeUrl(url: string) {
  return `https://www.youtube.com/embed/${getYoutubeID(
    url
  )}?autoplay=1&origin=${window.location.origin}&enablejsapi=1`;
}

export function getCloudflareID(url: string) {
  if (/^[0-9a-f]{4,64}$/.test(url)) {
    return url;
  }

  return url.replace(
    /.*(videodelivery.net|cloudflarestream.com)\/([0-9a-f]{4,64}).*/,
    "$2"
  );
}

export function getCloudflareThumb(url: string) {
  return `https://videodelivery.net/${getCloudflareID(
    url
  )}/thumbnails/thumbnail.jpg`;
}

export function getImageThumb(url: string) {
  return url.replace(/(\..{2,6})$/, "-150x150$1");
}

export function getMediaThumb(mediaItem: WpMedia) {
  return mediaItem?.image
    ? getImageThumb(mediaItem?.image)
    : mediaItem?.source === "youtube" || !mediaItem?.source
    ? getYoutubeThumb(mediaItem?.url?.default_url || "")
    : mediaItem?.source === "cloudflare"
    ? getCloudflareThumb(mediaItem?.video_ids?.default_id || "")
    : "";
}

export function isPortrait() {
  const x = window.innerWidth || document.body.clientWidth;
  const y = window.innerHeight || document.body.clientHeight;

  return y > x;
}
