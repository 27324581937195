import React from "react";
import clsx from "clsx";
import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "../../icons/ErrorIcon";
import { IconButton, makeStyles, Theme, Typography } from "@material-ui/core";
import MUISnackbar, { SnackbarOrigin } from "@material-ui/core/Snackbar";
import WarningIcon from "../../icons/WarningIcon";
import InfoIcon from "../../icons/InfoIcon";

interface SnackbarProps {
  autoHide?: boolean;
  anchorOrigin?: SnackbarOrigin;
  headline: string | React.ReactNode;
  message?: string | React.ReactNode;
  variant?: "error" | "warning" | "info";
  open: boolean;
  handleContainerClick?: () => void;
  handleClose?: () => void;
  children?: string | React.ReactNode;
  actionsBar?: string | React.ReactNode;
  classes?: {
    container?: string;
    headline?: string;
  };
}

const AUTO_HIDE_DURATION = 10000;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: "70px"
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    width: "400px",
    minHeight: "50px",
    background: "white",
    padding: "1em",
    borderRadius: "3px",
    boxShadow: "0 12px 24px 4px rgba(40,42,43,0.2)",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  contentContainer: {
    display: "flex",
    lineHeight: 1.8,
    width: "100%"
  },
  iconContainer: {
    display: "flex",
    padding: "0 1.3em 0 0.3em",
    transform: "translateY(3px)"
  },
  innerContentContainer: {
    width: "100%"
  },
  headline: {
    fontWeight: "bold",
    display: "flex",
    lineHeight: 1.8,
    width: "100%",
    justifyContent: "space-between"
  },
  headlineText: {
    flexGrow: 1
  },
  headlineBar: {
    marginLeft: 12
  },
  error: {
    borderLeft: "4px solid #D61F1F"
  },
  warning: {
    borderLeft: "4px solid #E46216"
  },
  info: {
    borderLeft: "4px solid #0263e0"
  }
}));

export default function Snackbar({
  autoHide = true,
  anchorOrigin,
  headline,
  message,
  variant,
  open,
  handleContainerClick,
  handleClose,
  actionsBar,
  children,
  classes: _classes
}: SnackbarProps) {
  const classes = useStyles();

  const handleOnClose = (
    _: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    handleClose?.();
  };

  const finalAnchorOrigin: SnackbarOrigin = {
    vertical: "top",
    horizontal: "right",
    ...anchorOrigin
  };

  return (
    <MUISnackbar
      anchorOrigin={finalAnchorOrigin}
      open={open}
      onClose={handleOnClose}
      autoHideDuration={autoHide ? AUTO_HIDE_DURATION : undefined}
      className={classes.root}
    >
      <div
        onClick={handleContainerClick}
        className={clsx(classes.container, _classes?.container, {
          [classes.error]: variant === "error",
          [classes.warning]: variant === "warning",
          [classes.info]: variant === "info"
        })}
      >
        <div className={classes.contentContainer}>
          {variant ? (
            <div className={classes.iconContainer}>
              {variant === "warning" && <WarningIcon />}
              {variant === "error" && <ErrorIcon />}
              {variant === "info" && <InfoIcon />}
            </div>
          ) : null}
          <div className={classes.innerContentContainer}>
            <Typography
              variant="body1"
              className={clsx(classes.headline, _classes?.headline)}
              component="span"
            >
              <span className={classes.headlineText}>{headline}</span>

              {actionsBar ? (
                <div className={classes.headlineBar}>{actionsBar}</div>
              ) : null}

              {handleClose && (
                <div className={classes.headlineBar}>
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={handleClose}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </div>
              )}
            </Typography>
            {message && (
              <Typography variant="body1" component="span">
                {" "}
                {message}
              </Typography>
            )}
            {children}
          </div>
        </div>
      </div>
    </MUISnackbar>
  );
}
