import { useCallback, useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { fetchToken, isAllowedHost, joinRoomWithWait } from "../APIs";
import { getLocalUser, setLocalUser } from "../localStorage";
import { getDisplayNameEmail } from "../../utils";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
};

const getUrlRoomName = () =>
  window.location.pathname?.match(/\/room\/(.+)/)?.[1];

export default function useFirebaseAuth() {
  const [user, setUser] = useState<firebase.User | null>(getLocalUser());
  const [isAuthReady, setIsAuthReady] = useState(Boolean(getLocalUser()));

  const rememberUser = useCallback(async newUser => {
    if (newUser) {
      const isAllowedHostUser = await isAllowedHost(newUser?.email || "");

      if (isAllowedHostUser) {
        setUser(newUser);
        setLocalUser(newUser);
      }
    }

    setIsAuthReady(true);
  }, []);

  const getToken = useCallback(
    async (user_identity: string, room_name: string) => {
      const urlRoomName = getUrlRoomName();

      if (user && !user.uid && user.displayName && urlRoomName) {
        await joinRoomWithWait({
          roomName: urlRoomName,
          participant: getDisplayNameEmail(user)
        });
      }

      return fetchToken({
        user_identity: user_identity || user?.displayName || "",
        room_name: room_name || urlRoomName || ""
      });
    },
    [user]
  );

  const updateRecordingRules = useCallback(
    async (room_sid, rules) => {
      const headers = new window.Headers();

      const idToken = await user!.getIdToken();
      headers.set("Authorization", idToken);
      headers.set("content-type", "application/json");

      return fetch("/api/twilio/recordingrules", {
        method: "POST",
        headers,
        body: JSON.stringify({ room_sid, rules })
      }).then(async res => {
        const jsonResponse = await res.json();

        if (!res.ok) {
          const recordingError = new Error(
            jsonResponse.error?.message ||
              "There was an error updating recording rules"
          );
          recordingError.code = jsonResponse.error?.code;
          return Promise.reject(recordingError);
        }

        return jsonResponse;
      });
    },
    [user]
  );

  useEffect(() => {
    firebase.initializeApp(firebaseConfig);
    firebase.auth().onAuthStateChanged(rememberUser);
  }, [rememberUser]);

  const signIn = useCallback(() => {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope("https://www.googleapis.com/auth/plus.login");

    return firebase
      .auth()
      .signInWithPopup(provider)
      .then(newUser => rememberUser(newUser.user));
  }, [rememberUser]);

  const signInAsGuest = useCallback(guestUser => {
    setLocalUser(guestUser);
    setUser(guestUser);
  }, []);

  const signOut = useCallback(() => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        setUser(null);
        setLocalUser(null);
      });
  }, []);

  return {
    user,
    signIn,
    signInAsGuest,
    signOut,
    isAuthReady,
    getToken,
    updateRecordingRules
  };
}
