import React, { useEffect } from "react";
import ParticipantInfo from "../ParticipantInfo/ParticipantInfo";
import ParticipantTracks from "../ParticipantTracks/ParticipantTracks";
import { Participant as IParticipant } from "twilio-video";
import { WpMessage } from "../../../commonTypes";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";

interface ParticipantProps {
  participant: IParticipant;
  videoOnly?: boolean;
  enableScreenShare?: boolean;
  onClick?: () => void;
  isSelected?: boolean;
  isLocalParticipant?: boolean;
  hideParticipant?: boolean;
}

export default function Participant({
  participant,
  videoOnly,
  enableScreenShare,
  onClick,
  isSelected,
  isLocalParticipant,
  hideParticipant
}: ParticipantProps) {
  const { setSharedMediaItem } = useVideoContext();

  useEffect(() => {
    const publicationSubscribed = (track: any) => {
      if (track.kind === "data") {
        track.on("message", (messageJson: string) => {
          const message: WpMessage = JSON.parse(messageJson);

          const action = message.action;
          const mediaItem = message.data || null;

          if (action === "withdraw") {
            setSharedMediaItem(null);
            return;
          }

          if (mediaItem) {
            setSharedMediaItem(mediaItem);
          }
        });
      }
    };

    // TODO: subscribe to messages from Hosts only
    participant.on("trackSubscribed", publicationSubscribed);

    return () => {
      participant.off("trackSubscribed", publicationSubscribed);
    };
  }, [setSharedMediaItem, participant]);

  return (
    <ParticipantInfo
      participant={participant}
      onClick={onClick}
      isSelected={isSelected}
      isLocalParticipant={isLocalParticipant}
      hideParticipant={hideParticipant}
    >
      <ParticipantTracks
        participant={participant}
        videoOnly={videoOnly}
        enableScreenShare={enableScreenShare}
        isLocalParticipant={isLocalParticipant}
      />
    </ParticipantInfo>
  );
}
