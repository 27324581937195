import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles, Theme } from "@material-ui/core";
import ChatWindow from "../ChatWindow/ChatWindow";
import ParticipantList from "../ParticipantList/ParticipantList";
import MainParticipant from "../MainParticipant/MainParticipant";
import BackgroundSelectionDialog from "../BackgroundSelectionDialog/BackgroundSelectionDialog";
import MediaSelectionDialog from "../MediaSelectionDialog/MediaSelectionDialog";
import useChatContext from "../../hooks/useChatContext/useChatContext";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import { useAppState } from "../../state";
import * as API from "../../state/APIs";
import { getDisplayNameEmail, ROOM_STATUS_CHECK_FREQ_SEC } from "../../utils";

const useStyles = makeStyles((theme: Theme) => {
  const totalMobileSidebarHeight = `${theme.sidebarMobileHeight +
    theme.sidebarMobilePadding * 2 +
    theme.participantBorderWidth}px`;
  return {
    container: {
      position: "relative",
      height: "100%",
      display: "grid",
      gridTemplateColumns: `1fr ${theme.sidebarWidth}px`,
      gridTemplateRows: "100%",
      [theme.breakpoints.down("sm")]: {
        gridTemplateColumns: `100%`,
        gridTemplateRows: `calc(100% - ${totalMobileSidebarHeight}) ${totalMobileSidebarHeight}`
      }
    },
    rightDrawerOpen: {
      gridTemplateColumns: `1fr ${theme.sidebarWidth}px ${theme.rightDrawerWidth}px`
    }
  };
});

export default function Room() {
  const classes = useStyles();
  const { isChatWindowOpen } = useChatContext();
  const { room, isBackgroundSelectionOpen } = useVideoContext();
  const { user } = useAppState();

  useEffect(() => {
    let timeout: any;

    const checkStatus = async () => {
      if (room?.state !== "disconnected") {
        try {
          const result = await API.checkRoomParticipant(
            room?.name || "",
            getDisplayNameEmail(user)
          );

          if (!result) {
            room!.disconnect();
          }
        } catch (err) {
          console.error(err);
        }
      }

      clearTimeout(timeout);
      timeout = setTimeout(checkStatus, ROOM_STATUS_CHECK_FREQ_SEC * 1000);
    };

    if (room?.name && room?.state !== "disconnected" && user && !user.uid) {
      checkStatus().then();
    }

    return function unmount() {
      clearTimeout(timeout);
      room?.name && API.checkRoomParticipant.abort(room?.name);
    };
  }, [room, user]);

  return (
    <div
      className={clsx("Room", classes.container, {
        [classes.rightDrawerOpen]: isChatWindowOpen || isBackgroundSelectionOpen
      })}
    >
      <MainParticipant />
      <ParticipantList />
      <ChatWindow />
      <BackgroundSelectionDialog />
      <MediaSelectionDialog />
    </div>
  );
}
