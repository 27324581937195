import { getAuthToken } from "./localStorage";
import { ROOM_STATUS_CHECK_FREQ_SEC } from "../utils";

const apiFetchControllers: any = {};

export function abortApiFetch(method: string, url: string) {
  apiFetchControllers?.[method]?.[url]?.abort();
}

export function apiFetch(
  method: string,
  url: string,
  body?: any,
  _headers: any = {}
) {
  const apiFetchController = new AbortController();
  const { signal } = apiFetchController;

  apiFetchControllers[method] = apiFetchControllers[method] || {};
  apiFetchControllers[method][url] = apiFetchController;

  const authToken = getAuthToken();

  const headers = {
    "Content-Type": "application/json",
    ...(authToken ? { Authorization: authToken } : {}),
    ..._headers
  };

  return fetch(url, {
    method: method,
    headers,
    ...(body ? { body: JSON.stringify(body) } : {}),
    ...(signal ? { signal } : {})
  }).then(async res => {
    const data = await res.json();

    if (res.status >= 400) {
      return Promise.reject(data);
    }

    return data;
  });
}

export function apiGet(url: string, _headers: any = {}) {
  return apiFetch("GET", url, null, _headers);
}

export function apiPost(url: string, body?: any, _headers: any = {}) {
  return apiFetch("POST", url, body, _headers);
}

export async function isAllowedHost(email: string) {
  if (!email) {
    return false;
  }

  return apiPost(`/api/allowedHosts/isAllowedHost`, { email });
}

export function fetchToken({ user_identity = "", room_name = "" } = {}) {
  return apiPost("/api/twilio/token", {
    user_identity,
    room_name,
    create_conversation:
      process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== "true"
  });
}

export function createNewRoom() {
  return apiPost(`/api/room/new`);
}

export function fetchRoom(roomId: string) {
  return apiGet(`/api/room/${roomId}`);
}
fetchRoom.abort = function(roomId: string) {
  return abortApiFetch("GET", `/api/room/${roomId}`);
};

export function addRoomParticipant(roomId: string, participant: string) {
  return apiPost(`/api/room/${roomId}/add`, { participant });
}

export function banRoomParticipant(roomId: string, participant: string) {
  return apiPost(`/api/room/${roomId}/ban`, { participant });
}

export function checkRoomParticipant(roomId: string, participant: string) {
  return apiPost(`/api/guest/${roomId}/check`, { participant });
}
checkRoomParticipant.abort = function(roomId: string) {
  return abortApiFetch("GET", `/api/guest/${roomId}/check`);
};

export async function joinRoomWithWait({
  roomName = "",
  participant = ""
} = {}) {
  return apiPost(`/api/guest/${roomName}/join`, { participant }).then(
    async joinResult => {
      if (!!joinResult) {
        return true;
      }

      const originalHref = window.location.href;
      const MAX_RETRIES = 30;
      let result = false;
      let retries = 0;

      while (retries < MAX_RETRIES && !result) {
        if (retries !== 0) {
          await new Promise(resolve =>
            setTimeout(resolve, ROOM_STATUS_CHECK_FREQ_SEC * 1000)
          );
        }

        retries++;

        if (window.location.href !== originalHref) {
          return false;
        }

        result = await checkRoomParticipant(roomName, participant);
      }

      if (!result) {
        return Promise.reject({
          message: `You weren't accepted to the room. Please try again later`
        });
      }

      return true;
    }
  );
}

export function fetchMedia() {
  return apiGet(`/api/media`);
}
