import firebase from "firebase/compat/app";

export function getLocalUser() {
  return JSON.parse(localStorage.getItem("user") || "null");
}

export function setLocalUser(user: firebase.User | null) {
  if (!user) {
    localStorage.removeItem("user");
  }

  localStorage.setItem("user", JSON.stringify(user));
}

export function getAuthToken(): string | null {
  const user = JSON.parse(localStorage.getItem("user") || "null");

  if (!user) {
    return null;
  }

  return user?.stsTokenManager?.accessToken || null;
}
