import { useEffect, useState } from "react";

export function useInputState(initialVal: any) {
  const [val, setVal] = useState<any>(initialVal);
  const [changedCount, setChangedCount] = useState<number>(0);

  useEffect(() => {
    setChangedCount(_changedCount => _changedCount + 1);
  }, [val]);

  return [val, changedCount > 1, setVal];
}
