import isPlainObject from "is-plain-object";
import { sha256 } from "js-sha256";

export const ROOM_STATUS_CHECK_FREQ_SEC = 3;

export const isMobile = (() => {
  if (
    typeof navigator === "undefined" ||
    typeof navigator.userAgent !== "string"
  ) {
    return false;
  }
  return /Mobile/.test(navigator.userAgent);
})();

// Recursively removes any object keys with a value of undefined
export function removeUndefineds<T>(obj: T): T {
  if (!isPlainObject(obj)) return obj;

  const target: { [name: string]: any } = {};

  for (const key in obj) {
    const val = obj[key];
    if (typeof val !== "undefined") {
      target[key] = removeUndefineds(val);
    }
  }

  return target as T;
}

export async function getDeviceInfo() {
  const devices = await navigator.mediaDevices.enumerateDevices();

  return {
    audioInputDevices: devices.filter(device => device.kind === "audioinput"),
    videoInputDevices: devices.filter(device => device.kind === "videoinput"),
    audioOutputDevices: devices.filter(device => device.kind === "audiooutput"),
    hasAudioInputDevices: devices.some(device => device.kind === "audioinput"),
    hasVideoInputDevices: devices.some(device => device.kind === "videoinput")
  };
}

// This function will return 'true' when the specified permission has been denied by the user.
// If the API doesn't exist, or the query function returns an error, 'false' will be returned.
export async function isPermissionDenied(name: PermissionName) {
  if (navigator.permissions) {
    try {
      const result = await navigator.permissions.query({ name });
      return result.state === "denied";
    } catch {
      return false;
    }
  } else {
    return false;
  }
}

export function isValidEmail(email: string): boolean {
  return !!email && /\S+@\S+\.\S+/i.test(email);
}

export function getDisplayNameEmail(user: any): string {
  return [
    user?.displayName || user?.name,
    user?.email ? `(${user?.email})` : ""
  ]
    .filter(Boolean)
    .join(" ");
}

export function getUniqDegree(input?: string | null): number {
  const hash = sha256(input || "").substr(0, 6);

  return parseInt(hash, 16) % 360;
}
export function getUniqDarkColour(input?: string | null): string {
  return `hsl(${getUniqDegree(input || "")}deg 90% 45%)`;
}
