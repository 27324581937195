import React, { useCallback, useEffect, useState } from "react";
import Snackbar from "../Snackbar/Snackbar";
import useRoomState from "../../hooks/useRoomState/useRoomState";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import * as API from "../../state/APIs";
import { Participant, Room } from "../../../commonTypes";
import { Button, makeStyles } from "@material-ui/core";
import { useAppState } from "../../state";
import { getDisplayNameEmail, ROOM_STATUS_CHECK_FREQ_SEC } from "../../utils";

const useStyles = makeStyles({
  root: {
    marginTop: 12
  },
  subSection: {
    marginBottom: "1rem",
    "&:last-child": {
      marginBottom: 0
    }
  },
  subSectionName: {
    fontWeight: "bold",
    lineHeight: "30px"
  },
  participant: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    marginBottom: 12
  },
  participantName: {
    flexGrow: 1,
    lineHeight: "30px"
  },
  participantAction: {
    marginLeft: "1em"
  },
  snackbarContainer: {
    width: "99vw",
    maxWidth: "600px"
  }
});

let timeout: any;

export default function RoomParticipantsPanel() {
  const classes = useStyles();

  const { user, showRoomParticipants, setShowRoomParticipants } = useAppState();
  const { room } = useVideoContext();
  const roomState = useRoomState();
  const [dbRoom, setDbRoom] = useState<Room | null>(null);
  const [isActionPending, setIsActionPending] = useState(false);

  const checkStatus = useCallback(async () => {
    clearTimeout(timeout);
    room?.name && API.fetchRoom.abort(room?.name);

    if (roomState !== "disconnected" && room?.name) {
      try {
        setDbRoom((await API.fetchRoom(room.name)) || null);
      } catch (err) {
        console.error(err);
      }
    }

    timeout = setTimeout(checkStatus, ROOM_STATUS_CHECK_FREQ_SEC * 1000);
  }, [room, roomState]);

  const moveUserInDbRoom = useCallback(
    (targetRoom, targetUser, action: "add" | "ban") => {
      clearTimeout(timeout);
      room?.name && API.fetchRoom.abort(room?.name);

      setDbRoom(prevDbRoom => {
        if (!prevDbRoom) {
          return null;
        }

        prevDbRoom.pending = prevDbRoom.pending.filter(
          _user => _user.name !== targetUser.name
        );

        if (action === "add") {
          prevDbRoom.banned = prevDbRoom.banned.filter(
            _user => _user.name !== targetUser.name
          );

          prevDbRoom.participants.push(targetUser);
        }

        if (action === "ban") {
          prevDbRoom.participants = prevDbRoom.participants.filter(
            _user => _user.name !== targetUser.name
          );

          prevDbRoom.banned.push(targetUser);
        }

        return { ...prevDbRoom };
      });

      setIsActionPending(true);

      const actionCb =
        action === "add" ? API.addRoomParticipant : API.banRoomParticipant;

      actionCb(targetRoom.name, targetUser.name)
        .catch()
        .then(() => {
          checkStatus().then();
          setIsActionPending(false);
        });
    },
    [room, checkStatus]
  );

  useEffect(() => {
    checkStatus().then();

    return function unmount() {
      clearTimeout(timeout);
      room?.name && API.fetchRoom.abort(room?.name);
    };
  }, [checkStatus, user, room]);

  return (
    <Snackbar
      autoHide={false}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      variant="info"
      headline="Participants"
      open={showRoomParticipants || !!dbRoom?.pending?.length}
      handleClose={
        showRoomParticipants
          ? () => setShowRoomParticipants(!showRoomParticipants)
          : undefined
      }
      classes={{
        container: classes.snackbarContainer
      }}
    >
      {room && dbRoom && (
        <div className={classes.root}>
          {(showRoomParticipants
            ? ["pending", "participants", "banned"]
            : ["pending"]
          ).map(group => (
            <div className={classes.subSection} key={group}>
              {!!(dbRoom as any)[group].length && (
                <div className={classes.subSectionName}>
                  {
                    ({
                      pending: "Pending users",
                      participants: "Room participants",
                      banned: "Banned users"
                    } as any)[group]
                  }
                </div>
              )}
              {(dbRoom as any)[group].map((_user: Participant) => (
                <div key={_user.name} className={classes.participant}>
                  <span className={classes.participantName}>
                    {getDisplayNameEmail(_user)}
                    {user?.displayName === _user.name &&
                      user?.email === _user.email &&
                      "(You)"}
                  </span>

                  {(group === "pending" || group === "banned") && (
                    <Button
                      color="primary"
                      variant="contained"
                      size="small"
                      disabled={isActionPending}
                      onClick={() => moveUserInDbRoom(room, _user, "add")}
                      className={classes.participantAction}
                    >
                      Add
                    </Button>
                  )}

                  {(group === "pending" || group === "participants") &&
                    !_user?.email && (
                      <Button
                        color="secondary"
                        variant="contained"
                        size="small"
                        disabled={isActionPending}
                        onClick={() => moveUserInDbRoom(room, _user, "ban")}
                        className={classes.participantAction}
                      >
                        Ban
                      </Button>
                    )}
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </Snackbar>
  );
}
