import React, { useState, useRef } from "react";
import AboutDialog from "../../AboutDialog/AboutDialog";
import BackgroundIcon from "../../../icons/BackgroundIcon";
import DeviceSelectionDialog from "../../DeviceSelectionDialog/DeviceSelectionDialog";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InfoIconOutlined from "../../../icons/InfoIconOutlined";
import MoreIcon from "@material-ui/icons/MoreVert";
import SearchIcon from "@material-ui/icons/Search";
import SettingsIcon from "../../../icons/SettingsIcon";
import {
  Button,
  styled,
  Theme,
  useMediaQuery,
  Menu as MenuContainer,
  MenuItem,
  Typography,
  Hidden
} from "@material-ui/core";
import { isSupported } from "@twilio/video-processors";

import useChatContext from "../../../hooks/useChatContext/useChatContext";
import useVideoContext from "../../../hooks/useVideoContext/useVideoContext";
import FlipCameraIcon from "../../../icons/FlipCameraIcon";
import useFlipCameraToggle from "../../../hooks/useFlipCameraToggle/useFlipCameraToggle";
import { VideoRoomMonitor } from "@twilio/video-room-monitor";
import { useAppState } from "../../../state";
import { ReactComponent as ScreenUsersIcon } from "../../../icons/screen-users.svg";
import SvgIcon from "@material-ui/core/SvgIcon";
import { ReactComponent as PhotoFilmIcon } from "../../../icons/photo-film.svg";

export const IconContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  width: "1.5em",
  marginRight: "0.3em"
});

function IconicMenuItem({
  children,
  ...props
}: {
  children: any[];
  onClick?: () => void;
  disabled?: boolean;
}) {
  return (
    <MenuItem {...props}>
      <IconContainer>{children[0]}</IconContainer>
      <Typography variant="body1">{children[1]}</Typography>
    </MenuItem>
  );
}

export default function Menu(props: { buttonClassName?: string }) {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const [aboutOpen, setAboutOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);

  const { user, setShowRoomParticipants, setShowMediaDialog } = useAppState();
  const { setIsChatWindowOpen } = useChatContext();
  const { setIsBackgroundSelectionOpen } = useVideoContext();

  const anchorRef = useRef<HTMLButtonElement>(null);
  const {
    flipCameraDisabled,
    toggleFacingMode,
    flipCameraSupported
  } = useFlipCameraToggle();

  return (
    <>
      <Button
        onClick={() => setMenuOpen(isOpen => !isOpen)}
        ref={anchorRef}
        className={props.buttonClassName}
        data-cy-more-button
      >
        {isMobile ? (
          <MoreIcon />
        ) : (
          <>
            More
            <ExpandMoreIcon />
          </>
        )}
      </Button>
      <MenuContainer
        open={menuOpen}
        onClose={() => setMenuOpen(isOpen => !isOpen)}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: isMobile ? -55 : "bottom",
          horizontal: "center"
        }}
      >
        {/*Host user functions:*/}

        {user?.uid && (
          <IconicMenuItem
            onClick={() => {
              setShowMediaDialog(true);
              setIsBackgroundSelectionOpen(false);
              setMenuOpen(false);
            }}
          >
            <SvgIcon component={PhotoFilmIcon} viewBox="0 0 640 512" />
            Share Media
          </IconicMenuItem>
        )}

        {user?.uid && (
          <IconicMenuItem
            onClick={() => {
              setShowRoomParticipants(true);
              setMenuOpen(false);
            }}
          >
            <SvgIcon component={ScreenUsersIcon} viewBox="0 0 640 512" />
            Room Participants
          </IconicMenuItem>
        )}

        <IconicMenuItem
          onClick={() => {
            setSettingsOpen(true);
            setMenuOpen(false);
          }}
        >
          <SettingsIcon /> Audio and Video Settings
        </IconicMenuItem>

        {isSupported && (
          <IconicMenuItem
            onClick={() => {
              setIsBackgroundSelectionOpen(true);
              setShowMediaDialog(false);
              setIsChatWindowOpen(false);
              setMenuOpen(false);
            }}
          >
            <BackgroundIcon /> Backgrounds
          </IconicMenuItem>
        )}

        {flipCameraSupported && (
          <IconicMenuItem
            disabled={flipCameraDisabled}
            onClick={toggleFacingMode}
          >
            <FlipCameraIcon /> Flip camera
          </IconicMenuItem>
        )}

        <Hidden smDown>
          <IconicMenuItem
            onClick={() => {
              VideoRoomMonitor.toggleMonitor();
              setMenuOpen(false);
            }}
          >
            <SearchIcon style={{ fill: "#707578", width: "0.9em" }} />
            Room Monitor
          </IconicMenuItem>
        </Hidden>

        <IconicMenuItem onClick={() => setAboutOpen(true)}>
          <InfoIconOutlined /> About
        </IconicMenuItem>
      </MenuContainer>

      <AboutDialog
        open={aboutOpen}
        onClose={() => {
          setAboutOpen(false);
          setMenuOpen(false);
        }}
      />

      <DeviceSelectionDialog
        open={settingsOpen}
        onClose={() => {
          setSettingsOpen(false);
          setMenuOpen(false);
        }}
      />
    </>
  );
}
