import React, { useState } from "react";
import { makeStyles, Theme, Typography } from "@material-ui/core";
import { useAppState } from "../../state";
import UserMenu from "./UserMenu/UserMenu";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Button from "@material-ui/core/Button";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgb(40, 42, 43)",
    height: "100%"
  },
  container: {
    position: "relative",
    flex: "1"
  },
  innerContainer: {
    display: "flex",
    width: 888,
    minHeight: 320,
    borderRadius: "8px",
    boxShadow: "0px 2px 4px 0px rgba(40, 42, 43, 0.3)",
    overflow: "hidden",
    position: "relative",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      height: "auto",
      width: "calc(100% - 40px)",
      margin: "auto",
      maxWidth: "400px",
      marginBottom: "40px",
      marginTop: "80px"
    }
  },
  swooshContainer: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: `url('/lady-berlinger-cognac-200-200-dark.png')`,
    backgroundSize: "tile",
    backgroundPosition: "center right",
    width: "296px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100px",
      backgroundPositionY: "140px"
    }
  },
  logoContainer: {
    position: "absolute",
    width: "210px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "center",
      width: "90%",
      textAlign: "initial",
      "& svg": {
        height: "64px"
      }
    }
  },
  content: {
    background: "white",
    width: "100%",
    padding: "4em 4em 6em 4em",
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      padding: "2em 2em 5em 2em"
    }
  },
  title: {
    color: "white",
    margin: "1em 0 0",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      fontSize: "1.1rem"
    }
  },
  loginBtn: {
    position: "absolute",
    top: "1rem",
    right: "1rem",
    color: "white",
    border: "none"
  },
  errorMessage: {
    color: "red",
    display: "flex",
    alignItems: "center",
    margin: "1em 0 0.2em",
    "& svg": {
      marginRight: "0.4em"
    }
  },
  serviceLinksBlock: {
    position: "absolute",
    bottom: 0,
    display: "flex",
    right: 0,
    padding: "1em 4em",
    [theme.breakpoints.down("sm")]: {
      padding: "0.5em 2em"
    }
  },
  serviceLink: {
    marginLeft: "1em"
  }
}));

interface IntroContainerProps {
  className?: string;
  children: React.ReactNode;
}

const IntroContainer = ({ className = "", children }: IntroContainerProps) => {
  const classes = useStyles();
  const { user, signIn } = useAppState();
  const location = useLocation();
  const { urlRoomName } = useParams();
  const history = useHistory();
  const [authError, setAuthError] = useState<Error | null>(null);

  const login = () => {
    setAuthError(null);
    signIn?.("")
      .then(() => {
        history.replace({
          pathname: urlRoomName ? `/room/${urlRoomName}` : "/"
        });
      })
      .catch(err => setAuthError(err));
  };

  return (
    <div className={`IntroContainer ${className} ${classes.background}`}>
      {(user && !location.pathname.includes("/login") && <UserMenu />) || (
        <Button variant="outlined" className={classes.loginBtn} onClick={login}>
          Login
        </Button>
      )}
      <div className={classes.container}>
        <div className={classes.innerContainer}>
          <div className={classes.swooshContainer}>
            <div className={classes.logoContainer}>
              <img
                src="/android-chrome-512x512.png"
                alt="Haus Berlinger Logo"
                width="125"
              />
              <Typography variant="h6" className={classes.title}>
                Haus Berlinger
                <br />
                Live Party
              </Typography>
            </div>
          </div>
          <div className={classes.content}>
            {children}

            {authError && (
              <Typography variant="caption" className={classes.errorMessage}>
                <ErrorOutlineIcon />
                {authError.message || authError.toString()}
              </Typography>
            )}
          </div>

          <div className={classes.serviceLinksBlock}>
            <a
              href="https://hausberlinger.com/datenschutz/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography variant="subtitle1" className={classes.serviceLink}>
                Privacy Policy
              </Typography>
            </a>
            <a
              href="https://hausberlinger.com/impressum/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography variant="subtitle1" className={classes.serviceLink}>
                Imprint
              </Typography>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroContainer;
