import React, { useEffect, useMemo, useState } from "react";
import YouTube from "react-youtube";
import { Stream as Cloudflare } from "@cloudflare/stream-react";
import Snackbar from "../Snackbar/Snackbar";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import { Button, makeStyles } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {
  getCloudflareID,
  getYoutubeID,
  isPortrait
} from "../../utils/mediaUtils";
import clsx from "clsx";
import { useAppState } from "../../state";
import { createStyles, Theme } from "@material-ui/core/styles";
import useLocalAudioToggle from "../../hooks/useLocalAudioToggle/useLocalAudioToggle";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    snackbarContainer: {
      width: "90vw",
      height: "85vh",
      transition: "none !important",
      [theme.breakpoints.down("sm")]: {
        width: "100vw",
        height: "calc(100vh - 170px)"
      }
    },
    snackbarContainerMinimized: {
      height: 55,
      maxWidth: 310,
      cursor: "pointer"
    },
    screenSharingShiftedMinimized: {
      transform: "translate(0px, -90px) !important"
    },
    snackbarContainerTitleMinimized: {
      textAlign: "center"
    },
    snackbarContainerTitleIcon: {
      verticalAlign: "top",
      marginRight: 6,
      color: "#303538"
    },
    root: {
      marginTop: 12,
      display: "flex",
      flexDirection: "column",
      height: "calc(100% - 32px)",
      margin: "0 -13px -20px -13px",
      borderRadius: "0 0 3px 3px",
      overflow: "hidden"
    },
    rootMinimized: {
      display: "none"
    },
    sharedImg: {
      objectFit: "contain",
      flex: 1,
      width: "100%",
      height: "100%"
    },
    videoIFrame: {
      flex: 1,
      width: "100%",
      height: "100%",
      padding: "0 !important"
    },

    subSection: {
      marginBottom: "1rem",
      "&:last-child": {
        marginBottom: 0
      }
    },
    subSectionName: {
      fontWeight: "bold",
      lineHeight: "30px"
    },
    participant: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      marginBottom: 12
    },
    participantName: {
      flexGrow: 1,
      lineHeight: "30px"
    },
    participantAction: {
      marginLeft: "1em"
    }
  })
);

export default function SharedMediaPanel() {
  const classes = useStyles();
  const [isMinimized, setIsMinimized] = useState(false);
  const [youTubePlayer, setYouTubePlayer] = useState<any>(null);
  const cloudflareRef = React.useRef<any>(null);
  const { user } = useAppState();
  const [isVideoMediaPlaying, setIsVideoMediaPlaying] = useState<boolean>(
    false
  );
  const { isSharingScreen } = useVideoContext();
  const [isAudioEnabled, , localAudioTrack] = useLocalAudioToggle();
  const [
    isAudioEnabledBeforePlaying,
    setIsAudioEnabledBeforePlaying
  ] = useState<boolean | null>(null);

  const {
    sendWithdrawMsg,
    sharedMediaItem,
    setSharedMediaItem
  } = useVideoContext();

  const imageUrl = useMemo(() => {
    if (!sharedMediaItem || sharedMediaItem.type !== "images") {
      return "";
    }

    return sharedMediaItem?.image || "";
  }, [sharedMediaItem]);

  const videoId = useMemo(() => {
    if (!sharedMediaItem || sharedMediaItem.type !== "videos") {
      return "";
    }

    if (sharedMediaItem.source === "youtube" || !sharedMediaItem.source) {
      const videoUrl =
        (isPortrait()
          ? sharedMediaItem?.url?.mobile_url
          : sharedMediaItem?.url?.default_url) ||
        sharedMediaItem?.url?.default_url ||
        "";

      return getYoutubeID(videoUrl);
    }

    if (sharedMediaItem.source === "cloudflare") {
      const videoUrl =
        (isPortrait()
          ? sharedMediaItem?.video_ids?.mobile_id
          : sharedMediaItem?.video_ids?.default_id) ||
        sharedMediaItem?.video_ids?.default_id ||
        "";

      return getCloudflareID(videoUrl);
    }

    return "";
  }, [sharedMediaItem]);

  useEffect(() => {
    // isAudioEnabledBeforePlaying === null indicates that video just started playing
    if (isVideoMediaPlaying && isAudioEnabledBeforePlaying === null) {
      setIsAudioEnabledBeforePlaying(isAudioEnabled);
    }

    // isAudioEnabledBeforePlaying !== null indicates that video just stopped playing
    if (!isVideoMediaPlaying && isAudioEnabledBeforePlaying !== null) {
      setIsAudioEnabledBeforePlaying(null);
    }

    if (
      isVideoMediaPlaying &&
      isAudioEnabled &&
      isAudioEnabledBeforePlaying === null
    ) {
      localAudioTrack.disable();
    }

    if (
      !isVideoMediaPlaying &&
      !isAudioEnabled &&
      isAudioEnabledBeforePlaying === true
    ) {
      localAudioTrack.enable();
    }
  }, [
    isAudioEnabled,
    isVideoMediaPlaying,
    localAudioTrack,
    isAudioEnabledBeforePlaying
  ]);

  useEffect(() => {
    if (sharedMediaItem) {
      setIsMinimized(false);
    }

    if (sharedMediaItem?.type !== "videos") {
      setYouTubePlayer(null);
      cloudflareRef.current = null;
    }

    if (sharedMediaItem && sharedMediaItem.source === "cloudflare") {
      setYouTubePlayer(null);
    }

    if (
      sharedMediaItem &&
      (sharedMediaItem.source === "youtube" || !sharedMediaItem.source)
    ) {
      cloudflareRef.current = null;
    }
  }, [sharedMediaItem]);

  useEffect(() => {
    if (!youTubePlayer && !cloudflareRef?.current) {
      return;
    }

    if (isMinimized) {
      youTubePlayer?.pauseVideo && youTubePlayer.pauseVideo();
      cloudflareRef?.current && cloudflareRef?.current.pause();
    } else {
      youTubePlayer?.playVideo && youTubePlayer.playVideo();
      cloudflareRef?.current && cloudflareRef?.current.play();
    }
  }, [youTubePlayer, cloudflareRef, isMinimized]);

  return (
    <Snackbar
      autoHide={false}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      headline={
        isMinimized ? (
          <div className={classes.snackbarContainerTitleMinimized}>
            <VisibilityIcon className={classes.snackbarContainerTitleIcon} />
            {sharedMediaItem?.type === "images"
              ? "Click to see shared image"
              : "Click to watch shared video"}
          </div>
        ) : sharedMediaItem?.type === "images" ? (
          "Shared Image"
        ) : (
          "Shared video"
        )
      }
      actionsBar={
        !isMinimized &&
        user?.uid && (
          <Button
            size="small"
            color="primary"
            variant="outlined"
            onClick={() => {
              sendWithdrawMsg();
              setSharedMediaItem(null);
            }}
          >
            Stop Sharing
          </Button>
        )
      }
      open={!!sharedMediaItem}
      handleContainerClick={
        isMinimized ? () => setIsMinimized(false) : undefined
      }
      handleClose={isMinimized ? undefined : () => setIsMinimized(true)}
      classes={{
        container: clsx(
          classes.snackbarContainer,
          isMinimized ? classes.snackbarContainerMinimized : null,
          isMinimized && isSharingScreen
            ? classes.screenSharingShiftedMinimized
            : null
        )
      }}
    >
      {sharedMediaItem && (
        <div
          className={clsx(
            classes.root,
            isMinimized ? classes.rootMinimized : null
          )}
        >
          {imageUrl && (
            <img
              src={imageUrl}
              className={classes.sharedImg}
              alt={sharedMediaItem.title}
            />
          )}

          {videoId &&
            (sharedMediaItem?.source === "youtube" ||
              !sharedMediaItem?.source) && (
              <YouTube
                videoId={videoId}
                className={classes.videoIFrame}
                iframeClassName={classes.videoIFrame}
                title={sharedMediaItem.title}
                opts={{
                  height: null,
                  width: null,
                  playerVars: { autoplay: 1, fullscreen: 1 }
                }}
                onReady={event => setYouTubePlayer(event.target)}
                onStateChange={({
                  data: state
                }: {
                  target: any;
                  data: number;
                }) => setIsVideoMediaPlaying(state === 1)}
              />
            )}

          {videoId && sharedMediaItem?.source === "cloudflare" && (
            <Cloudflare
              controls
              autoplay
              src={videoId}
              streamRef={cloudflareRef}
              onPlay={() => setIsVideoMediaPlaying(true)}
              onPause={() => setIsVideoMediaPlaying(false)}
              className={classes.videoIFrame}
            />
          )}
        </div>
      )}
    </Snackbar>
  );
}
